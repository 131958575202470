
import rules from "@/util/rules";
export default new class {
    //初始化fromData
    dataInit() {
        return {
            isLiberty: true,
            title: '新增规格',
            hide: false,
            imageSrc: "",
            imageShow: false,
            typeList: [], //型号列表
            planeList: [], //定制面列表
            loading: false,
            labelPosition: "right",
            initStatus: false,
            //表单数据
            fromData: {
                spec_id: '',
                brand_id: null, //手机品牌id
                type_id: null, //手机型号id
                type_name: null,
                supplier_id: null, //供应商id
                prod_id: null, //产品id
                prod_name: null,
                plane_id: null, //定制面id
                model_id: null, //模型id
                exportWidth: null, //导出宽度
                exportHeight: null, //导出高度
                top: null, //上边距
                left: null, //左边距
                width: null, //手机壳宽度
                height: null, //手机壳高度
                thickness: null, //厚度
                round: null, //圆角
                image: null, //手机壳图片
                imagePreview: null, //手机壳图片预览base64
                figureImage: '',//实物图片
            },
            suppliers: [], //供应商数据列表
            supplierIndex: -1, //当前供应商
            rules: {
                prod_id: [rules.noNullChange("请选择产品")],
                brand_id: [
                    rules.noNullChange("请选择手机品牌")],
                type_id: [
                    rules.noNullChange("请选择手机型号")],
                supplier_id: [
                    rules.noNullBlur()
                ],
                plane_id: [
                    rules.noNullBlur()
                ],
                exportWidth: [
                    rules.noNullBlur()
                ],
                exportHeight: [
                    rules.noNullBlur()
                ],
                top: [
                    rules.noNullBlur()
                ],
                left: [
                    rules.noNullBlur()
                ],
                width: [
                    rules.noNullBlur()
                ],
                height: [
                    rules.noNullBlur()
                ],
                thickness: [
                    rules.noNullBlur()
                ],
                round: [
                    rules.noNullBlur()
                ],
            },
        }
    }

    //创建一个供应商
    addSupplier(suppliers, id, priority, plane_id) {
        suppliers.push({
            id, //供应商id
            priority, //优先级
            attrs: {
                extras: {
                    thickness: "", //厚度
                },
                sides: {
                    [plane_id]: {
                        //定制面id
                        id: plane_id, //定制面id
                        width: "",
                        height: "",
                        ltround: "",
                        rtround: "",
                        rbround: "",
                        lbround: "",
                        caremaimage: "",
                        exportWidth: "850",
                        exportHeight: "1701",
                        top: "",
                        left: "",
                        figureImage: []
                    },
                },
            },
        });
    }
    //切换供应商
    tabfromDataChange(fromData, item, sides) {
        fromData.thickness = item.attrs.extras.thickness || '';
        fromData.supplier_id = item.id || '';
        fromData.width = sides.width || '';
        fromData.height = sides.height || '';
        fromData.exportWidth = sides.exportWidth || '';
        fromData.exportHeight = sides.exportHeight || '';
        fromData.round = sides.ltround || '';
        fromData.image = sides.caremaimage || '';
        fromData.top = sides.top || '';
        fromData.left = sides.left || '';
        fromData.figureImage = JSON.parse(item.attrs.extras.figureImage) || '';
    }
    //监听的规格属性
    fromDataChange(fromData) {
        return {
            thickness: fromData.thickness,
            supplier_id: fromData.supplier_id,
            width: fromData.width,
            height: fromData.height,
            exportWidth: fromData.exportWidth,
            exportHeight: fromData.exportHeight,
            round: fromData.round,
            image: fromData.image,
            top: fromData.top,
            left: fromData.left,
            figureImage: fromData.figureImage
        }
    }
    //监听规格属性修改
    watchAssignSuppliers(supplierItem, plane_id, fromData) {
        supplierItem.attrs.sides[plane_id].width = fromData.width;
        supplierItem.attrs.sides[plane_id].height = fromData.height;
        supplierItem.attrs.sides[plane_id].exportWidth = fromData.exportWidth;
        supplierItem.attrs.sides[plane_id].exportHeight = fromData.exportHeight;
        supplierItem.attrs.sides[plane_id].ltround = fromData.round;
        supplierItem.attrs.sides[plane_id].rtround = fromData.round;
        supplierItem.attrs.sides[plane_id].rbround = fromData.round;
        supplierItem.attrs.sides[plane_id].lbround = fromData.round;
        supplierItem.attrs.sides[plane_id].top = fromData.top;
        supplierItem.attrs.sides[plane_id].left = fromData.left;
        supplierItem.attrs.sides[plane_id].caremaimage = fromData.image;
        supplierItem.attrs.extras.figureImage = JSON.stringify(fromData.figureImage)
        supplierItem.attrs.extras.thickness = fromData.thickness;

    }
}
<template>
  <div class="image-show" v-show="myShow" @click="handleHideClick">
    <el-image class="image" :src="src" fit="contain"></el-image>
  </div>
</template>
<script>
export default {
  name: "ImageShow",
  props: {
    src: String,
    show: Boolean,
  },
  data() {
    return {
      myShow: false,
    };
  },
  watch: {
    show() {
      this.myShow = true;
    },
  },
  methods: {
    handleHideClick() {
      this.myShow = false;
    },
  },
};
</script>
<style lang="less" scoped>
.image-show {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.7);
  z-index: 9999999999;
  .image {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 50%;
    height: 50%;
    transform: translate(-50%, -50%);
  }
}
</style>
<template>
  <ky-dialog :title="title" :show="show" :hide="hide" width="40%">
    <el-form
      slot="dialog-content"
      :label-position="labelPosition"
      label-width="100px"
      :model="fromData"
      :rules="rules"
      ref="fromData"
    >
      <el-row :gutter="10">
        <el-col :span="7">
          <el-form-item label-width="100px" label="产品:" prop="prod_id">
            <el-select
              filterable
              :disabled="!isLiberty"
              v-model="fromData.prod_id"
              placeholder="请选择产品"
            >
              <el-option
                v-for="item in prodList"
                :key="item.id"
                :label="item.title"
                :value="item.id"
              >
              </el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="7">
          <el-form-item label="手机品牌:" prop="brand_id">
            <el-select
              filterable
              :disabled="!isLiberty"
              v-model="fromData.brand_id"
              placeholder="请选择手机品牌"
            >
              <el-option
                v-for="item in brandList"
                :key="item.id"
                :label="item.name"
                :value="item.id"
              >
              </el-option> </el-select></el-form-item
        ></el-col>
        <el-col :span="7">
          <el-form-item label="手机型号:" prop="type_id">
            <el-select
              filterable
              :disabled="!isLiberty"
              v-model="fromData.type_id"
              placeholder="请选择手机型号"
            >
              <el-option
                v-for="item in typeList"
                :key="item.id"
                :label="item.name"
                :value="item.id"
              >
              </el-option>
            </el-select>
          </el-form-item>
        </el-col>
      </el-row>

      <el-row>
        <el-col :span="12">
          <el-form-item label="供应商:" prop="supplier_id">
            <el-col :span="24">
              <el-select
                filterable
                v-model="fromData.supplier_id"
                placeholder="选择供应商"
                @change="handleAddSupplier"
              >
                <el-option
                  v-for="item in supplierList"
                  :key="item.id"
                  :label="item.name"
                  :value="item.id"
                >
                </el-option>
              </el-select>
            </el-col>
            <el-col :span="24">
              <div class="tag-container">
                <div
                  class="tagItem"
                  v-for="(item, index) in suppliers"
                  :key="item.id"
                >
                  <el-tag
                    closable
                    icon="el-icon-top"
                    :type="supplierIndex === index ? '' : 'info'"
                    :effect="supplierIndex === index ? 'dark' : 'plain'"
                    @click="handleTabfromDataChange(index)"
                    @close="handleDelSupplier(index)"
                  >
                    {{ item.id | showSupplierName(supplierList) }}
                  </el-tag>
                  <i
                    class="iconfont"
                    :class="
                      item.priority === 1 ? 'el-icon-star-on' : 'el-icon-top'
                    "
                    :title="
                      item.priority === 1 ? '优先级最高' : '设置为优先级最高'
                    "
                    @click="handleSettingPriority(index, item)"
                  ></i>
                </div>
              </div>
            </el-col>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="定制面:" prop="plane_id">
            <el-select v-model="fromData.plane_id" placeholder="请选择定制面">
              <el-option
                v-for="item in planeList"
                :key="item.id"
                :label="item.name"
                :value="item.id"
              >
              </el-option>
            </el-select>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="12">
          <el-form-item label="导出宽度:" prop="exportWidth">
            <el-input
              v-model.number="fromData.exportWidth"
              placeholder="请输入导出宽度"
            />
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="导出高度:" prop="exportHeight">
            <el-input
              v-model.number="fromData.exportHeight"
              placeholder="请输入导出高度"
            />
          </el-form-item>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="12">
          <el-form-item label="上边距:" prop="top">
            <el-input
              v-model.number="fromData.top"
              placeholder="请输入上边距"
            />
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="左边距:" prop="left">
            <el-input
              v-model.number="fromData.left"
              placeholder="请输入左边距"
            />
          </el-form-item>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="12">
          <el-form-item label="手机壳宽度:" prop="width">
            <el-input
              v-model.number="fromData.width"
              placeholder="手机壳宽度"
            />
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="手机壳高度:" prop="height">
            <el-input
              v-model.number="fromData.height"
              placeholder="手机壳高度"
            />
          </el-form-item>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="12">
          <el-form-item label="厚度:" prop="thickness">
            <el-input
              v-model.number="fromData.thickness"
              placeholder="请填写厚度"
            />
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="圆角:" prop="round">
            <el-input
              v-model.number="fromData.round"
              placeholder="请填写圆角"
            />
          </el-form-item>
        </el-col>
      </el-row>
      <el-form-item label="装饰图片:">
        <div class="upload-image-container">
          <div class="image-container" v-if="fromData.image">
            <div class="image-item">
              <ky-image
                :images="[fromData.image ? base_url + fromData.image : '']"
                :src="fromData.image ? base_url + fromData.image : ''"
              />
            </div>
          </div>
          <upload-image @getImage="handleGetImage"></upload-image>
        </div>
      </el-form-item>
      <el-form-item label="实物图片:">
        <div class="upload-image-container">
          <div class="image-container">
            <div
              class="image-item"
              v-for="item of fromData.figureImage"
              :key="item"
            >
              <ky-image
                :images="[item ? base_url + item : '']"
                :src="item ? base_url + item : ''"
              />
            </div>
          </div>
          <upload-image @getImage="handleGoodsImage"></upload-image>
        </div>
        <!-- <el-col :span="14">
          <upload-image @getImage="handleGoodsImage"></upload-image>
        </el-col> -->
      </el-form-item>
    </el-form>
    <span slot="dialog-footer" class="dialog-footer">
      <el-button @click="cancelAdd">取 消</el-button>
      <el-button type="primary" @click="handleComfirm">确 定</el-button>
    </span>
  </ky-dialog>
</template>
<script>
import { mapState } from "vuex";
import rules from "@/util/rules";
import specModule from "./module";
import ImageShow from "@/components/ImageShow";
import UploadImage from "@/components/UploadImage";
export default {
  name: "AddProd",
  props: {
    show: Boolean,
    type: String,
    item: Object,
    prodList: Array,
    specId: Number,
  },
  components: { ImageShow, UploadImage },
  data() {
    return specModule.dataInit();
  },
  computed: {
    ...mapState(["brandList", "supplierList"]),
    //监听规格属性
    fromDataChange(data) {
      return specModule.fromDataChange(this.fromData);
    },
  },
  watch: {
    async show() {
      this.initStatus = false;
      if (this.type === "add") {
        this.fromData = specModule.dataInit().fromData;
        this.isLiberty = true;
        this.suppliers = [];
        await this.handleInit();
        this.initStatus = true;
      } else {
        await this.handleInit();
        this.fromData.spec_id = this.specId;
        this.isLiberty = false;
        await this.getGoodsSpecSumInfo();
        this.initStatus = true;
      }
    },
    "fromData.brand_id"() {
      this.getBrandFindType();
    },
    //监听规格属性修改
    fromDataChange() {
      if (!this.initStatus) {
        return;
      } else if (this.suppliers.length === 0 && this.initStatus) {
        this.$message({
          message: "请先添加供应商",
          type: "error",
        });
        return;
      }
      this.handleWatchAssignSuppliers();
    },
  },
  methods: {
    //初始化
    async handleInit() {
      this.fromData.model_id = this.item.modelId;
      this.fromData.prod_id = this.item.prodId;
      this.fromData.brand_id = this.item.brandId;
      this.fromData.type_id = this.item.typeId;
      this.fromData.figureImage = [];
      this.suppliers = [];
      this.supplierIndex = -1; //当前供应商
      await this.getBrandFindType();
      await this.getAllSupplier();
      await this.getModelFindPlane();
    },
    //获取型号
    async getBrandFindType() {
      const brand_id = this.fromData.brand_id;
      if (brand_id) {
        const res = await this.$store.dispatch("getBrandFindType", {
          brand_id: JSON.stringify([brand_id]),
        });
        this.typeList = res.data;
      }
    },
    //获取对应模型支持的定制面
    async getModelFindPlane() {
      const sides = await this.$store.dispatch("getModelFindPlane", {
        model_id: this.fromData.model_id,
      });
      this.planeList = Object.values(sides);
      this.fromData.plane_id = this.planeList[0].id;
      return true;
    },
    //获取所有供应商
    async getAllSupplier() {
      if (this.supplierList.length === 0) {
        await this.$store.dispatch("getAllSuppliers");
      }
    },
    //获取规格信息
    async getGoodsSpecSumInfo() {
      if (!this.specId) {
        return;
      }
      const data = await this.$store.dispatch("getGoodsSpecSumInfo", {
        spec_id: this.specId,
      });
      this.suppliers = data.suppliers.sort((a, b) => {
        return a.priority - b.priority;
      });
      this.suppliers.some((item, index) => {
        item.priority === 1;
        if (item.priority === 1) {
          this.supplierIndex = index;
        }
        this.handleTabfromDataChange(this.supplierIndex);
        return;
      });
    },
    //供应商选择
    handleSupplierChange() {
      this.handleAddSupplier();
    },
    //创建一个供应商
    handleAddSupplier(id) {
      const bool = this.suppliers.some((item) => {
        return item.id === id;
      });
      if (bool) {
        return;
      }
      const plane_id = this.fromData.plane_id;
      const priority = this.suppliers.length === 0 ? 1 : 2;
      specModule.addSupplier(this.suppliers, id, priority, plane_id);
      this.supplierIndex = this.suppliers.length - 1;
      this.handleTabfromDataChange(this.supplierIndex);
    },
    //删除供应商
    handleDelSupplier(index) {
      this.supplierIndex =
        this.supplierIndex > index
          ? this.supplierIndex - 1
          : this.supplierIndex;
      this.suppliers.splice(index, 1);
    },
    //切换供应商
    handleTabfromDataChange(index) {
      this.supplierIndex = index;
      const item = this.suppliers[index];
      const sides = item.attrs.sides[this.fromData.plane_id];
      specModule.tabfromDataChange(this.fromData, item, sides);
    },
    //设置供应商优先级
    handleSettingPriority(index, item) {
      this.suppliers.splice(index, 1);
      this.suppliers.unshift(item);
      this.suppliers.map((item, index) => {
        item.priority = index + 1;
      });
      this.supplierIndex = 0;
      // this.suppliers[index].priority = 1;
    },
    //监听规格属性修改
    handleWatchAssignSuppliers() {
      this.$tool.debounce(100).then(() => {
        const fromData = this.fromData;
        const plane_id = this.fromData.plane_id;
        let supplierItem = this.suppliers[this.supplierIndex];
        specModule.watchAssignSuppliers(supplierItem, plane_id, fromData);
      });
    },
    //查看图片
    handleImageShow(src) {
      this.imageShow = !this.imageShow;
      this.imageSrc = src;
    },
    //上传装饰图片
    async handleGetImage(files) {
      console.log(files[0]);
      let formDatas = new FormData();
      formDatas.append("goods_id", this.fromData.prod_id);
      formDatas.append("sup_id", this.fromData.supplier_id);
      formDatas.append("side_id", this.fromData.plane_id);
      formDatas.append("attr_en_name", "caremaimage");
      formDatas.append("image", files[0]);
      const res = await this.$store.dispatch("uploadAdornImage", formDatas);
      this.$tool.comfirmToast(res).then(() => {
        this.fromData.image = res.url;
      });
    },
    //上传实物图片
    async handleGoodsImage(files) {
      const imageList = Object.values(files);
      const urls = imageList.map((item) => {
        return this.handleUploadImage(item);
      });
      Promise.all(urls).then((res) => {
        this.fromData.figureImage = res.map((item) => {
          return item.url;
        });
        // this.fromData.figureImage = JSON.stringify(figureImage);
        console.log(this.fromData.figureImage);
      });
    },
    //上传图片
    async handleUploadImage(file) {
      let formDatas = new FormData();
      formDatas.append("goods_id", this.fromData.prod_id);
      formDatas.append("sup_id", this.fromData.supplier_id);
      formDatas.append("side_id", this.fromData.plane_id);
      formDatas.append("attr_en_name", "figureImage");
      formDatas.append("image", file);
      return await this.$store.dispatch("uploadAdornImage", formDatas);
    },
    //取消
    cancelAdd() {
      this.hide = !this.hide;
    },
    //确定
    async handleComfirm() {
      const fromData = this.fromData;
      console.log(this.suppliers);
      let data = {
        // spec_id: fromData.spec_id,
        goods_id: fromData.prod_id,
        values: JSON.stringify({
          type_id: fromData.type_id,
          suppliers: this.suppliers,
        }),
      };
      if (fromData.spec_id) {
        data.spec_id = fromData.spec_id;
      }
      const res = await this.$store.dispatch("createOrEditGoodsSpec", data);
      this.$tool.comfirmToast(res).then(() => {
        this.$emit("succ");
        this.hide = !this.hide;
      });
    },
  },
  filters: {
    //根据id获取对应的供应商name
    showSupplierName(id, list) {
      const item = list.find((item) => {
        return item.id === id;
      });
      return item.name;
    },
  },
};
</script>
<style lang="less" scoped>
.upload-image-container {
  display: flex;
  align-items: center;
  .image-container {
    display: flex;
    align-items: center;
    .image-item {
      margin-right: 5px;
      width: 50px;
      height: 50px;
      position: relative;
    }
  }
}
.item-container {
  display: flex;
  align-items: center;
  .selectBox,
  .el-icon-plus,
  .el-icon-minus {
    margin-left: 10px;
  }
}
.dialog-footer {
  display: flex;
  justify-content: flex-end;
}
.fileUpload-container {
  position: absolute;
  height: 100%;
  display: flex;
  align-items: center;
  .image-preview {
    margin-right: 20px;
    width: 60px;
    height: 60px;
  }
  .fileUpload-btn {
    position: relative;
  }
  .fileUpload-inp {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
  }
}
.tag-container {
  display: flex;
  flex-wrap: wrap;
  align-content: center;
  .tagItem {
    margin-right: 10px;
    margin-bottom: 10px;
    display: flex;
    align-items: center;
  }
  .iconfont {
    cursor: pointer;
    font-size: 20px;
    &.el-icon-star-on {
      color: #e6a23c;
    }
    &.el-icon-top {
      color: #f56c6c;
    }
  }
}
</style>
